import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import PuzzleIcon from '../../assets/images/product/business-outcomes/puzzle-icon.svg'
import HeartIcon from '../../assets/images/product/business-outcomes/heart-icon.svg'
import PieIcon from '../../assets/images/product/business-outcomes/pie-icon.svg'
import InitiativesTag from '../../assets/images/product/business-outcomes/initiatives-tag.svg'
import BalanceTag from '../../assets/images/product/business-outcomes/investment-balance-tag.svg'
import CapitalizationTag from '../../assets/images/product/business-outcomes/software-capitalization-tag.svg'
import Tag from '../../assets/images/product/business-outcomes/business-outcomes-tag.svg'
import MetaImage from '../../assets/images/product/business-outcomes/meta.png'
import Layout from '../../components/Layout'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import Blurb from '../../components/system/Blurb'
import Box from '../../components/primitives/Box'
import CTABlock from '../../components/blocks/CTABlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/business-outcomes/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/business-outcomes/video-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
    balance: file(
      relativePath: {
        eq: "images/product/business-outcomes/investment-balance.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    initiatives: file(
      relativePath: { eq: "images/product/business-outcomes/initiatives.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
    capitalization: file(
      relativePath: {
        eq: "images/product/business-outcomes/software-capitalization.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1000)
      }
    }
  }
`

const OutcomesPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Business outcomes"
      variant="dark"
      isNew
      description="CTOs, VPEs, and engineering directors use the business outcomes lens in Swarmia to make decisions that move the business in the right direction."
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <HeroBlock
          tag={Tag}
          title="Predictably deliver on planned business outcomes"
          content={
            <>
              CTOs, VPEs, and engineering directors use Swarmia to make
              decisions that move the business
              in&nbsp;the&nbsp;right&nbsp;direction.
            </>
          }
          backgroundImage={getImage(data.backgroundImage)!}
        />
        <LeadBlock
          heading={<>Lead your organization with&nbsp;confidence</>}
          content="Swarmia gives you the visibility you need to make informed decisions about priorities, people, and processes."
        />
        <VideoBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          videoId="EJ-ZLe45dJE"
          thumbnail={getImage(data.videoThumbnail)!}
        />
        <ColumnsBlock
          paddingTop={responsiveScale(32)}
          paddingBottom={responsiveScale(64)}
          columns={[
            <Blurb
              key="1"
              title="Promote a healthy culture"
              text="Instead of spying on or micromanaging your teams, Swarmia encourages transparency and autonomy."
              icon={HeartIcon}
            />,
            <Blurb
              key="2"
              title="Report to the business"
              text="From strategic initiatives to software capitalization, get the exact data you need to report back to the business."
              icon={PieIcon}
            />,
            <Blurb
              key="3"
              title="Empower managers and teams"
              text="In addition to executive dashboards, Swarmia gives teams the tools they need to do their best work."
              icon={PuzzleIcon}
            />,
          ]}
        />
        <QuoteBlock person="maryanna" />
        <LeadBlock
          icon={BalanceTag}
          heading="Balance engineering investment"
          content="Understand where engineering time goes and make informed investment decisions."
          link={{
            title: 'Read more about investment balance',
            href: '/product/investment-balance/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.balance)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={InitiativesTag}
          heading={<>Take surprises out of&nbsp;feature&nbsp;delivery</>}
          content="Stay on top of cross-team initiatives, catch delivery risks early, and communicate progress to non-engineering stakeholders."
          link={{
            title: 'Read more about initiatives',
            href: '/product/initiatives/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.initiatives)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <LeadBlock
          icon={CapitalizationTag}
          heading="Create audit-ready software capitalization reports"
          content="Pull capitalizable engineering work from Swarmia, easily blend with salary data, and create cost capitalization reports."
          link={{
            title: 'Read more about software capitalization',
            href: '/product/software-capitalization/',
          }}
        />
        <Box>
          <IllustrationBlock
            paddingTop={responsiveScale(32)}
            paddingBottom={responsiveScale(64)}
            image={getImage(data.capitalization)!}
            noPlaceHolder
            rounded
            shadow
          />
        </Box>
        <QuoteBlock person="pau" />
        <Box paddingBottom={responsiveScale(32)}>
          <CTABlock
            title={
              <>Start delivering on the planned business&nbsp;outcomes today</>
            }
          />
          <FeaturedBlogArticles
            heading="More from the swarmia blog"
            articles={[
              '/blog/balancing-engineering-investments/',
              '/blog/cross-team-initiatives/',
              '/blog/developer-productivity-goals-signals-metrics/',
            ]}
            newStyles
          />
        </Box>
      </GlobalStyleReset>
    </Layout>
  )
}

export default OutcomesPage
